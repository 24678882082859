
import {defineComponent, reactive, ref,onActivated} from "vue"
import ApprovalTable from "@/components/ApprovalTable.vue"
import InvoiceFree from "@/views/InvoiceFree.vue"
import InvoiceManagement from "@/views/ViewsComponents/paymentPool/InvoiceManagement.vue"
import InoviceAuthentication from "@/views/ViewsComponents/paymentPool/InoviceAuthentication.vue"
import TaxAuthority from "@/views/ViewsComponents/paymentPool/TaxAuthority.vue"
import dayjs from "dayjs"
import PaymentModuleUtils from "@/views/typeMapping/PaymentModuleUtils"
import Api from "@/http/modules/index"
let { statusFilter, filterType } = PaymentModuleUtils
export default defineComponent({
    name:'FinanaceApproval',
     components:{
        ApprovalTable,
        InvoiceFree,
        InvoiceManagement,
        InoviceAuthentication,
        TaxAuthority
    },
    setup(){
        const {
            'POST/approved/records':approvalHistoryApi,
            // 财务审批记录
            'POST/approved/sec_record':financeApprovalHistoryApi,
            //下拉框选项
            'GET/pay_pool/drop_list': drapListApi,
            'GET/users/list': userListApi
        } = Api
        const approvalHistoryData=ref([])
        const historyTableLoading=ref(false)
        // const tableLoading=ref(false)
        const historyTabLoading=ref(false)
        const typeList=ref([])
        const userList=ref<any>([])
        userListApi().then((res:any)=>userList.value=res)
        drapListApi().then((res:any)=>{
            typeList.value=res.type
        })
        const historyPageSetting=reactive({
            page:1,
            size:40,
            totalNum:0
        })
        const pageSetting=reactive({
            page:1,
            size:40,
            totalNum:0
        })
        const historyDetailData=ref([])
        const financeApprovalHistoryList=ref([])
        const queryForm=ref({
            no:"",
            approvedRecordId:"",
            payApproveStatus:"APPROVE_SEC",
            approvedBy:"",
            reimburseType:"",
            financeApproveStatusList: [],
        })
        const activeNames=ref("")
        const queryHisForm=ref({
            no:"",
            approvedRecordId:"",
            approvedBy:"",
            approvedSecBy:"",

        })
        const transformationTime=(time:string)=>{
            return dayjs(time).format("YYYY-MM-DD HH:mm:ss")
        }
        const openApprovalHitory=()=>{
            
            let { page, size }=historyPageSetting
            historyTableLoading.value=true
            approvalHistoryApi({page, size, query: queryForm.value} as any).then((res:any)=>{
                approvalHistoryData.value=res.data
                historyPageSetting.totalNum=res.rowCount
                historyTableLoading.value = false
            }).catch(()=>{
                historyTableLoading.value=false
            })
        }
        // 执行一次获取历史记录数据
        openApprovalHitory()  
        const queryMethod=(isQuery:boolean)=>{
            historyPageSetting.page=1
            historyPageSetting.size=40
            if(!isQuery){
                queryForm.value={
                    no:"",
                    approvedRecordId:"",
                    payApproveStatus:"APPROVE_SEC",
                    approvedBy:"",
                    reimburseType:"",
                    financeApproveStatusList: [],
                }  
            }
            openApprovalHitory()
        }      
        const hisPageChange=(page:number)=>{
            historyPageSetting.page=page
            openApprovalHitory() 
        }  
        const hisSizeChange=(size:number)=>{
            historyPageSetting.size=size
            openApprovalHitory() 
        }   
        const getFinanceApprovalHistoryList=()=>{
            let {page,size}=pageSetting
            historyTabLoading.value=true
            financeApprovalHistoryApi({page,size,query:queryHisForm.value}).then((res:any)=>{
                financeApprovalHistoryList.value=res.data  
                pageSetting.totalNum=res.rowCount
                historyTabLoading.value=false  
            }).catch(()=>{
                historyTabLoading.value=false
            })
        }   
        getFinanceApprovalHistoryList()  
        const financeHisPageChange=(page:number)=>{
            pageSetting.page=page
            getFinanceApprovalHistoryList()  
        }
        const financeHisSizeChange=(size:number)=>{
            pageSetting.size=size
            getFinanceApprovalHistoryList()  
        }
        const queryHisMethod=(isQuery:boolean)=>{
            pageSetting.page=1
            pageSetting.size=40
            if(!isQuery){
                queryHisForm.value={
                   no:"",
                    approvedRecordId:"",
                    approvedBy:"",
                    approvedSecBy:"",
                }  
            }
            getFinanceApprovalHistoryList()
        }
        onActivated(()=>{
            openApprovalHitory()
        })
        return{
           approvalHistoryData,
            historyTableLoading,
            historyPageSetting,
            transformationTime,
            hisPageChange,
            historyDetailData,
            // tableLoading,
            pageSetting,
            statusFilter,
            financeApprovalHistoryList,
            financeHisPageChange,
            activeNames,
            queryForm,
            queryMethod,
            queryHisForm,
            queryHisMethod,
            filterType,
            typeList,
            userList,
            historyTabLoading,
            hisSizeChange,
            financeHisSizeChange,
            tableHeight: window.innerHeight,
            
        }
    }
})
