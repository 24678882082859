import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createBlock(_component_el_table, {
      data: _ctx.tableData,
      border: "",
      "max-height": _ctx.MI_height - 100 + 'px'
    }, {
      default: _withCtx(() => [
        (_ctx.showApprovedRecordId)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 0,
              label: "打包编号",
              prop: "approvedRecordId",
              formatter: (row) => 'DB' + row.approvedRecordId
            }, null, 8, ["formatter"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_table_column, {
          label: "序号",
          prop: "no",
          fixed: ""
        }),
        _createVNode(_component_el_table_column, {
          label: "发起人",
          prop: "creator",
          "show-overflow-tooltip": ""
        }),
        _createVNode(_component_el_table_column, {
          label: "公司",
          prop: "payeeCompany",
          width: "150px",
          "show-overflow-tooltip": ""
        }),
        _createVNode(_component_el_table_column, {
          label: "场地名",
          prop: "workPlace",
          width: "150px",
          "show-overflow-tooltip": ""
        }),
        _createVNode(_component_el_table_column, {
          label: "费用申请部门",
          prop: "department",
          width: "150px",
          "show-overflow-tooltip": ""
        }),
        _createVNode(_component_el_table_column, {
          label: "摘要",
          "show-overflow-tooltip": "",
          prop: "note",
          width: "180px"
        }, {
          default: _withCtx(({row}) => [
            _createElementVNode("span", null, _toDisplayString(row.note), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "费用类型",
          prop: "type",
          width: "120px"
        }, {
          default: _withCtx(({row}) => [
            _createTextVNode(_toDisplayString(_ctx.filterType(row.type)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "申请费用",
          prop: "estimateAmount",
          width: "120px",
          formatter: _ctx.formatNumber,
          align: "right"
        }, null, 8, ["formatter"]),
        _createVNode(_component_el_table_column, {
          label: "支付金额",
          prop: "amount",
          width: "110px",
          formatter: _ctx.formatNumber,
          align: "right"
        }, null, 8, ["formatter"]),
        _createVNode(_component_el_table_column, {
          label: "单据收集人",
          prop: "cashier",
          width: "150px"
        }),
        _createVNode(_component_el_table_column, {
          label: "支付状态",
          prop: "status",
          width: "120px"
        }, {
          default: _withCtx(({row}) => [
            _createTextVNode(_toDisplayString(_ctx.statusFilter(row.status)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "支付时间",
          prop: "payAt",
          width: "110px"
        }, {
          default: _withCtx(({row}) => [
            _createTextVNode(_toDisplayString(row.payAt ? _ctx.dayjs(row.createTime).format("YYYY-MM-DD") : ""), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "创建时间",
          prop: "createTime",
          width: "110px"
        }, {
          default: _withCtx(({row}) => [
            _createTextVNode(_toDisplayString(_ctx.dayjs(row.createTime).format("YYYY-MM-DD")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "审批状态",
          prop: "approvedStatus",
          width: "120px"
        }, {
          default: _withCtx(({row}) => [
            _createTextVNode(_toDisplayString(_ctx.statusFilter(row.approvedStatus)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "回退原因",
          prop: "reason",
          "show-overflow-tooltip": "",
          width: "120px"
        }),
        _createVNode(_component_el_table_column, {
          label: "票据状态",
          prop: "isUploadedInvoice",
          "show-overflow-tooltip": "",
          width: "120px"
        }, {
          default: _withCtx(({row}) => [
            _createTextVNode(_toDisplayString(row.isUploadedInvoice?'已上传':""), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "驳回原因",
          prop: "rejectReason",
          width: "150px",
          "show-overflow-tooltip": ""
        }),
        (_ctx.canEdit)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 1,
              fixed: "right",
              label: "操作",
              prop: "ops",
              width: "180px",
              align: "center"
            }, {
              default: _withCtx(({row}) => [
                (row.status !== 'PAYMENT_REISSUED')
                  ? (_openBlock(), _createBlock(_component_el_space, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_link, {
                          disabled: row.locked==1  || row.status === 'PAY_FAILED',
                          onClick: ($event: any) => (_ctx.editTax(row)),
                          type: "primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.editText), 1)
                          ]),
                          _: 2
                        }, 1032, ["disabled", "onClick"]),
                        _renderSlot(_ctx.$slots, "back", { data: row })
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              _: 3
            }))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["data", "max-height"])), [
      [_directive_loading, _ctx.tableLoading]
    ]),
    (_ctx.showPagination)
      ? (_openBlock(), _createBlock(_component_el_pagination, {
          key: 0,
          small: "",
          background: "",
          layout: "prev, pager, next,total",
          total: _ctx.totalNumber,
          class: "mb-5 mt-5",
          onCurrentChange: _ctx.pageChange,
          "page-size": _ctx.pageSize,
          currentPage: _ctx.page,
          "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event))
        }, null, 8, ["total", "onCurrentChange", "page-size", "currentPage"]))
      : _createCommentVNode("", true)
  ], 64))
}