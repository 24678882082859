
import { defineComponent, PropType, onBeforeMount, toRefs, watch, ref } from 'vue'
import dayjs from 'dayjs'
import tbMix from '@/global_mixin/tableMixin'
import { formatNumber, getNumber } from '@/utils/index'
import PaymentModuleUtils from "@/views/typeMapping/PaymentModuleUtils"
let {statusFilter,filterType}=PaymentModuleUtils
let {MI_height} = new tbMix(() => Promise.resolve())
export default defineComponent({
    props:{
        tableData:{
            type:Array,
            default:[]
        },
        tableLoading:{
            type:Boolean,
            default:false
        },
        totalNumber:{
            type:Number,
            default:0
        },
        showPagination:{
            type:Boolean,
            default:true
        },
        canEdit:{
            type:Boolean,
            default:true
        },
        editText:{
            type:String,
            default:"编辑"
        },
        showApprovedRecordId:{
            type:Boolean,
            default:false
        }
    },
    emits:['pageChange','edit'],
    setup(props,{slots,attrs,emit}){
        const page=ref(1)
        const pageSize=ref(20)
        const pageChange=(val:any)=>{
            emit('pageChange',page)
        }
        const editTax=(row:any)=>{
            emit('edit',row)
        }
        return{
            filterType, 
            statusFilter,
            pageChange,
            page,
            pageSize,
            editTax,
            dayjs,
            formatNumber,
            getNumber,
            MI_height,
        }
    }
})
